import React, { useRef, useState, useEffect } from 'react';
import './App.css';  // Import your CSS

const IDScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [frontImageBlob, setFrontImageBlob] = useState(null);
  const [backImageBlob, setBackImageBlob] = useState(null);
  const [step, setStep] = useState('front'); // To track whether we're taking the front or back picture

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }, // Use rear camera
        });
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error("Error accessing the camera:", error);
        alert("Camera access is denied or not available.");
      }
    };

    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Use canvas.toBlob to capture image as a file instead of base64
    canvas.toBlob((blob) => {
      if (step === 'front') {
        setFrontImageBlob(blob);  // Save front image as a Blob
        setStep('back');  // Move to the next step (take back picture)
      } else if (step === 'back') {
        setBackImageBlob(blob);  // Save back image as a Blob
      }
    }, 'image/jpeg', 0.95);  // Capture the image as a JPEG Blob with 95% quality
  };

  const submitImages = async () => {
    const formData = new FormData();
    if (frontImageBlob) {
      formData.append('front', frontImageBlob, 'front.jpg');  // Append the front image as a file
    }
    if (backImageBlob) {
      formData.append('back', backImageBlob, 'back.jpg');  // Append the back image as a file
    }

    try {
      const response = await fetch('https://idscan.247tim.rs/api/upload-id', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to upload images');
      }
      const result = await response.json();
      alert('Slike su uspešno poslate!');
    } catch (error) {
      console.error('Error submitting images:', error);
      alert('Failed to upload images.');
    }
  };

  return (
    <div className="container">
      <h2>SLIKAJTE LIČNU KARTU</h2>
      <div className="video-wrapper">
        <video ref={videoRef} autoPlay playsInline className="video" />
        <div className="overlay">
          <div className="id-window"></div> {/* ID-sized window */}
        </div>
      </div>
      {!backImageBlob ? (
        <button onClick={captureImage} className="capture-btn">
          {step === 'front' ? 'SLIKAJTE PREDNJU STRANU LIČNE KARTE' : 'SLIKAJTE ZADNJU STRANU LIČNE KARTE'}
        </button>
      ) : (
        <div className="image-review">
          <h3>SLIKE LIČNE KARTE:</h3>
          <img src={URL.createObjectURL(frontImageBlob)} alt="PREDNJA STRANA LK" className="captured-image" />
          <img src={URL.createObjectURL(backImageBlob)} alt="ZADNJA STRANA LK" className="captured-image" />
          <button onClick={submitImages} className="submit-btn">Pošaljite slike</button>
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default IDScanner;
